export default {
  SwiperList: [
    [
      // 국내
      {
        img: 'partnership_group01.png',
        title: 'Amazon AWS',
      },
      {
        img: 'partnership_group02.png',
        title: 'E-Land',
      },
      {
        img: 'partnership_group03.png',
        title: 'SK 주식회사 C&C',
      },
      {
        img: 'partnership_group04.png',
        title: '신패스 DHN',
      },
      {
        img: 'partnership_group05.png',
        title: '교원',
      },
      {
        img: 'partnership_group06.png',
        title: '현대',
      },
      {
        img: 'partnership_group07.png',
        title: '오라클',
      },
      {
        img: 'partnership_group08.png',
        title: '하나금융티아이',
      },
      {
        img: 'partnership_group09.png',
        title: 'FLOW',
      },
      {
        img: 'partnership_group10.png',
        title: 'NHN ENTERTAINMENT',
      },
      {
        img: 'partnership_group11.png',
        title: '잇츠한불',
      },
      {
        img: 'partnership_group12.png',
        title: '㈜장진우',
      },
      {
        img: 'partnership_group13.png',
        title: 'GRAP',
      },
      {
        img: 'partnership_group14.png',
        title: '토스',
      },
      {
        img: 'partnership_group15.png',
        title: '온아시아',
      },
      {
        img: 'partnership_group16.png',
        title: '하이보이스',
      },
      {
        img: 'partnership_group17.png',
        title: 'Jober',
      },
      {
        img: 'partnership_group18.png',
        title: 'TAGby',
      },
      {
        img: 'partnership_group19.png',
        title: '두잇컴퍼니',
      },
      {
        img: 'partnership_group20.png',
        title: '스티비',
      },
      {
        img: 'partnership_group21.png',
        title: '디라이트',
      },
      {
        img: 'partnership_group22.png',
        title: '삼정KPMG',
      },
      {
        img: 'partnership_group23.png',
        title: '모두투어',
      },
      {
        img: 'partnership_group24.png',
        title: '바이엘',
      },
      {
        img: 'partnership_group25.png',
        title: '예스24',
      },
      {
        img: 'partnership_group26.png',
        title: '고려대의료원',
      },
      {
        img: 'partnership_group28.png',
        title: '소니',
      },
      {
        img: 'partnership_group29.png',
        title: '플래디',
      },
      {
        img: 'partnership_group30.png',
        title: '중소기업진흥공단',
      },
      {
        img: 'partnership_group31.png',
        title: '한국방송광고진흥공사',
      },
      {
        img: 'partnership_group32.png',
        title: '한국무역협회',
      },
      {
        img: 'partnership_group33.png',
        title: '창업진흥원',
      },
      {
        img: 'partnership_group34.png',
        title: '판교창업존',
      },
      {
        img: 'partnership_group35.png',
        title: '영등포구',
      },
      {
        img: 'partnership_group27.png',
        title: '충남창조경제',
      },
      {
        img: 'partnership_group36.png',
        title: 'Maru 180',
      },
      {
        img: 'partnership_group37.png',
        title: 'The Ventures',
      },
      {
        img: 'partnership_group38.png',
        title: 'Ventire Square',
      },
      {
        img: 'partnership_group39.png',
        title: '롯데엑셀러레이터',
      },
      {
        img: 'partnership_group40.png',
        title: '원익투자파트너스',
      },
      {
        img: 'partnership_group41.png',
        title: '글로벌브레인',
      },
      {
        img: 'partnership_group42.png',
        title: '서울투자파트너스',
      },
      {
        img: 'partnership_group43.png',
        title: 'N15',
      },
      {
        img: 'partnership_group44.png',
        title: 'HGI',
      },
      {
        img: 'partnership_group45.png',
        title: 'Primer',
      },
      {
        img: 'partnership_group46.png',
        title: '디캠프(은행권청년창업재단)',
      },
      {
        img: 'partnership_group47.png',
        title: '1QLAB',
      },
      {
        img: 'partnership_group48.png',
        title: '신한 FUTURES LAB',
      },
      {
        img: 'partnership_group49.png',
        title: 'FINOLAB',
      },
      {
        img: 'partnership_group50.png',
        title: '위비핀테크 LAB',
      },
      {
        img: 'partnership_group51.png',
        title: '핀테크지원센터',
      },
      {
        img: 'partnership_group52.png',
        title: '법무법인 세움',
      },
      {
        img: 'partnership_group53.png',
        title: 'BLT특허사무소',
      },
      {
        img: 'partnership_group54.png',
        title: '무일국제특허법률사무소',
      },
      {
        img: 'partnership_group55.png',
        title: '퓨처플레이',
      },
      {
        img: 'partnership_group56.png',
        title: '코오롱 액셀러레이터',
      },
      {
        img: 'partnership_group57.png',
        title: '코스콤 액셀러레이터',
      },
      {
        img: 'partnership_group58.png',
        title: '매쉬업엔젤스',
      },
      {
        img: 'partnership_group59.png',
        title: '서울대학교 기술지주회사',
      },
      {
        img: 'partnership_group60.png',
        title: '서강 비즈니스 센터',
      },
      {
        img: 'partnership_group61.png',
        title: '성균관대학교 산학협력단',
      },
      {
        img: 'partnership_group62.png',
        title: '동국대학교 창업지원단',
      },
      {
        img: 'partnership_group63.png',
        title: '단국대학교 산학협력단',
      },
      {
        img: 'partnership_group64.png',
        title: '순천향대학교 창업보육센터',
      },
      {
        img: 'partnership_group65.png',
        title: '충남대학교 산학협력단',
      },
      {
        img: 'partnership_group66.png',
        title: '이화여자대학교',
      },
      {
        img: 'partnership_group67.png',
        title: '한양대학교 산학협력단',
      },
    ],
    [
      // 해외
      {
        img: 'partnership_group101.png',
        title: 'Almack Mezzanine',
      },
      {
        img: 'partnership_group102.png',
        title: 'Alpinvest',
      },
      {
        img: 'partnership_group103.png',
        title: 'Ares',
      },
      {
        img: 'partnership_group104.png',
        title: 'Blackstone',
      },
      {
        img: 'partnership_group105.png',
        title: 'Carlyle',
      },
      {
        img: 'partnership_group106.png',
        title: 'CD&R',
      },
      {
        img: 'partnership_group107.png',
        title: 'Coller Capital',
      },
      {
        img: 'partnership_group108.png',
        title: 'Crestview Partners',
      },
      {
        img: 'partnership_group109.png',
        title: 'Goldman Sachs',
      },
      {
        img: 'partnership_group110.png',
        title: 'IFC',
      },
      {
        img: 'partnership_group111.png',
        title: 'KKR',
      },
      {
        img: 'partnership_group112.png',
        title: 'New Mountain Capital',
      },
      {
        img: 'partnership_group113.png',
        title: 'PAI',
      },
      {
        img: 'partnership_group114.png',
        title: 'Providence',
      },
      {
        img: 'partnership_group115.png',
        title: 'Vietnam Investment Group',
      },
      {
        img: 'partnership_group116.png',
        title: 'Anthemis',
      },
      {
        img: 'partnership_group117.png',
        title: 'Fintech Collective',
      },
      {
        img: 'partnership_group118.png',
        title: 'Golden Gate Ventures',
      },
      {
        img: 'partnership_group119.png',
        title: 'Pitango Venture Capital',
      },
      {
        img: 'partnership_group120.png',
        title: 'Vertex Ventures',
      },
      {
        img: 'partnership_group121.png',
        title: 'Wavemaker Partners',
      },
      {
        img: 'partnership_group122.png',
        title: 'Finolab',
      },
      {
        img: 'partnership_group123.png',
        title: 'ONL',
      },
      {
        img: 'partnership_group124.png',
        title: 'Rakuten',
      },
      {
        img: 'partnership_group125.png',
        title: 'China Accelerator',
      },
      {
        img: 'partnership_group126.png',
        title: 'INNOSPACE',
      },
      {
        img: 'partnership_group127.png',
        title: 'Egg',
      },
      {
        img: 'partnership_group128.png',
        title: 'VIISA',
      },
      {
        img: 'partnership_group129.png',
        title: 'MAD INCUBATO',
      },
      {
        img: 'partnership_group130.png',
        title: 'AIR ASIA',
      },
      {
        img: 'partnership_group131.png',
        title: 'Pinehurst Advisors',
      },
      {
        img: 'partnership_group132.png',
        title: 'Fatfish Internet Group',
      },
      {
        img: 'partnership_group133.png',
        title: 'IdesSpace',
      },
      {
        img: 'partnership_group134.png',
        title: 'M8VC',
      },
      {
        img: 'partnership_group135.png',
        title: 'Ideasource',
      },
      {
        img: 'partnership_group136.png',
        title: 'GSF',
      },
    ],
  ],
};
