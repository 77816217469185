<template>
  <!-- 알림 팝업 -->
  <div class="popup-wrap notice"> <!-- notice 클래스 추가 -->
      <!-- 알림 팝업 스타일1 -->
      <div class="popup" ref="pop-corona">
          <div class="popup-contents">
              <h6 class="popup-title">드림플러스 강남에서<br> 안내드립니다.</h6>
              <p class="message">
                  드림플러스 강남에서는 코로나 감염 확산세의 변동에 따라 센터 투어 방문(방문 예약) 및 이벤트홀/멀티룸 <br>대관 예약을
                  <span class="underline-text">10월 5일부터 제한적으로 재개</span>하고자<br>합니다.<br><br>
                  이후 변동 사항 발생시 팝업을 통해 별도 재공지 드리겠습니다.
              </p>
              <p class="txt_sub from">
                  <br>- 드림플러스 강남
              </p>
          </div>
          <div class="popup-check">
              <div class="checkbox-container">
                  <input class="input-cbx" id="cbx1" type="checkbox"
                  @click="doNotAccPop['pop-corona'] = (doNotAccPop['pop-corona']? false : true)"/>
                  <label class="checkbox" for="cbx1">
                      <span>
                          <svg>
                              <polyline points="1.5 6 4.5 9 12.5 1"></polyline>
                          </svg>
                      </span>
                      <span class="text">7일간 다시 보지 않기</span>
                  </label>
              </div>
          </div>
          <button @click="closePop('pop-corona')" class="btn-close">닫기</button>
      </div>
      <!-- // 알림 팝업 스타일1 -->
  </div>
  <!-- // 알림 팝업 -->
</template>
<script>
export default {
  data() {
    return {
      doNotAccPop: [],
    };
  },
  props: {
    visibleCorona: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closePop(tag) {
      const $target = this.$refs[tag];
      $target.classList.add('hide');
      // eslint-disable-next-line no-unused-expressions
      this.doNotAccPop[tag] && this.$cookies.set(tag, true, '7d', '/');
      if (this.$refs['pop-corona'].classList.contains('hide')) {
        this.$emit('close');
      }
    },
  },
};
</script>
