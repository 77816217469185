<template>
  <!-- 알림 팝업 -->
  <div class="popup-wrap notice"> <!-- notice 클래스 추가 -->
      <!-- 알림 팝업 스타일1 -->
      <div class="popup" ref="pop-apply">
          <div class="popup-contents">
              <h6 class="popup-title">치매 케어 파트너사 모집</h6>
              <p class="message">
                  치매 예방 및 케어 플랫폼에 합류할 제품과 <br>서비스社를 모집합니다.<br><br>
                  기간연장 : '20. 12. 1(화) ~ '21. 1. 31(일) 5pm<br><br>
                  <button class="btn-basic" @click="goApply">
                    지원 바로가기
                  </button>
              </p>
          </div>
          <div class="popup-check">
              <div class="checkbox-container">
                  <input class="input-cbx" id="cbx1" type="checkbox"
                  @click="doNotAccPop['pop-apply'] = (doNotAccPop['pop-apply']? false : true)"/>
                  <label class="checkbox" for="cbx1">
                      <span>
                          <svg>
                              <polyline points="1.5 6 4.5 9 12.5 1"></polyline>
                          </svg>
                      </span>
                      <span class="text">오늘하루 열지않기</span>
                  </label>
              </div>
          </div>
          <button @click="closePop('pop-apply')" class="btn-close">닫기</button>
      </div>
      <!-- // 알림 팝업 스타일1 -->
  </div>
  <!-- // 알림 팝업 -->
</template>
<script>
export default {
  data() {
    return {
      doNotAccPop: [],
    };
  },
  props: {
    visibleCorona: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closePop(tag) {
      const $target = this.$refs[tag];
      $target.classList.add('hide');
      // eslint-disable-next-line no-unused-expressions
      this.doNotAccPop[tag] && this.$cookies.set(tag, true, '1d', '/');
      if (this.$refs['pop-apply'].classList.contains('hide')) {
        this.$emit('close');
      }
    },

    goApply() {
      this.$emit('close');
      this.$router.push({
        name: 'ProgramsApplication',
      });
    },
  },
};
</script>
