<template>
  <!-- 알림 팝업 -->
  <div class="popup-wrap hackathon_banner"> <!-- notice 클래스 추가 -->
    <!-- 알림 팝업 스타일1 -->
    <div class="popup" ref="pop-hackathon">
      <div class="popup-contents">
        <a href="javascript:void(0);" @click="eventGo">
          <img src="~@/assets/img/hackathon_banner.png" alt="Blockchain Hackathon">
        </a>
        <!-- <h6 class="popup-title">해커 공모전</h6>
        <p class="message">
          <a href="javascript:void(0);" @click="eventGo">공모전 참여</a>

        </p>
        <p class="txt_sub from">
          <br>- 드림플러스 강남
        </p> -->
      </div>
      <div class="popup-check">
        <div class="checkbox-container">
          <input class="input-cbx" id="cbx1" type="checkbox" v-model="checkValue"/>
          <label class="checkbox" for="cbx1">
                      <span>
                          <svg>
                              <polyline points="1.5 6 4.5 9 12.5 1"></polyline>
                          </svg>
                      </span>
            <span class="text">7일간 다시보지 않기</span>
          </label>
        </div>
      </div>
      <button @click="closePop('pop-hackathon')" class="btn-close"><span class="blind">닫기</span></button>
    </div>
    <!-- // 알림 팝업 스타일1 -->
  </div>
  <!-- // 알림 팝업 -->
</template>
<script>

export default {
  data() {
    return {
      checkValue: false,
    };
  },
  methods: {
    closePop(tag) {
      if (this.checkValue) {
        this.$cookies.set('pop-hackathon', true, '7d', '/');
      } else {
        this.$cookies.remove('pop-hackathon');
      }
      const $target = this.$refs[tag];
      $target.classList.add('hide');
      this.$emit('close');
      // eslint-disable-next-line no-unused-expressions
      /*
      this.doNotAccPop[tag] && this.$cookies.set(tag, true, '7d', '/');
      if (this.$refs['pop-hackathon'].classList.contains('hide')) {
        this.$emit('close');
      }
      */
    },
    eventGo() {
      sessionStorage.setItem('hackerton', { hackerton: 'enter' });
      // this.$router.push({
      //   name: 'hackathon',
      // });
      window.open('/programs/hackathon');
    },
  },
};
</script>
